var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"animated fadeIn"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Cadastro de Banners do Totem")])]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12","sm":"12"}},[_c('b-input-group',{attrs:{"prepend":"Empresa"}},[_c('b-form-select',{attrs:{"options":_vm.optionsCompany},on:{"change":function($event){return _vm.getSitesByCompany(_vm.selectedOptionCompany)}},model:{value:(_vm.selectedOptionCompany),callback:function ($$v) {_vm.selectedOptionCompany=$$v},expression:"selectedOptionCompany"}})],1)],1)],1),_c('div',{attrs:{"id":"web"}},[(_vm.selectedOptionCompany)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Lojas")])]},proxy:true}],null,false,758858593)},[_c('b-row',[_c('b-table',{ref:"selectableTable",staticClass:"tableSites",staticStyle:{"margin-top":"-15px","margin-bottom":"5px"},attrs:{"id":"tableSites","sticky-header":"","hover":"","striped":"","bordered":"","responsive":"sm","selectable":"","select-mode":_vm.selectMode,"items":_vm.sites,"fields":_vm.fieldsSites,"current-page":_vm.currentPage,"head-variant":"light"},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(" ")]),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}}],null,false,1405399763)})],1),_c('b-row',[_c('p',[_c('b-button',{staticClass:"btn",attrs:{"size":"sm"},on:{"click":_vm.clearSelected}},[_vm._v("Limpar seleção")]),_c('b-button',{staticClass:"btn",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.selectAllRows}},[_vm._v("Selecionar todas")])],1)])],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Banner")])]},proxy:true}],null,false,635608686)},[_c('picture-input',{ref:"pictureInput",staticStyle:{"margin-top":"-15px","margin-bottom":"-5px","width":"50%","height":"50%"},attrs:{"margin":"1","accept":"image/*","autoToggleAspectRatio":true,"removable":false,"size":"30","zIndex":10,"button-class":"changeBtn","removeButtonClass":"removeBtn","prefill":_vm.url,"custom-strings":{
                    upload: '<h1>Seu dispositivo não aceita este tipo de envio.</h1>',
                    drag: 'Arraste uma imagem <br> (ou clique aqui)',
                    change: 'Mudar foto',
                    remove: 'Remover foto',
                  }},on:{"change":_vm.onChange}})],1)],1)],1):_vm._e()],1),_c('div',{attrs:{"id":"mobile"}},[(_vm.selectedOptionCompany)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Lojas")])]},proxy:true}],null,false,758858593)},[_c('b-row',[_c('b-table',{ref:"selectableTableMobile",staticClass:"tableSites",staticStyle:{"margin-top":"-15px","margin-bottom":"5px"},attrs:{"id":"tableSitesMobile","sticky-header":"","hover":"","striped":"","bordered":"","responsive":"sm","selectable":"","select-mode":_vm.selectModeMobile,"items":_vm.sites,"fields":_vm.fieldsSitesMobile,"current-page":_vm.currentPage,"head-variant":"light","small":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
                  var rowSelected = ref.rowSelected;
return [(rowSelected)?[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("✓")]),_c('span',{staticClass:"sr-only"},[_vm._v("Selected")])]:[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v(" ")]),_c('span',{staticClass:"sr-only"},[_vm._v("Not selected")])]]}}],null,false,1405399763)})],1),_c('b-row',{staticStyle:{"margin-bottom":"-10px"}},[_c('p',[_c('b-button',{staticClass:"btn",attrs:{"size":"sm"},on:{"click":_vm.clearSelected}},[_vm._v("Limpar seleção")]),_c('b-button',{staticClass:"btn",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.selectAllRows}},[_vm._v("Selecionar todas")])],1)])],1)],1)],1):_vm._e(),(_vm.selectedOptionCompany)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"100%"},attrs:{"tag":"article","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"titleCard"},[_vm._v("Banner")])]},proxy:true}],null,false,635608686)},[_c('picture-input',{ref:"pictureInput",staticStyle:{"margin-top":"-15px","margin-bottom":"-5px","width":"100%"},attrs:{"margin":"1","accept":"image/*","autoToggleAspectRatio":true,"removable":false,"size":"40","zIndex":10,"button-class":"changeBtn","removeButtonClass":"removeBtn","prefill":_vm.url,"custom-strings":{
                    upload: '<h1>Seu dispositivo não aceita este tipo de envio.</h1>',
                    drag: 'Arraste uma imagem <br> (ou clique aqui)',
                    change: 'Mudar foto',
                    remove: 'Remover foto',
                  }},on:{"change":_vm.onChange}})],1)],1)],1):_vm._e()],1)],1)],1),_c('b-button',{staticClass:"my-2 my-sm-0 mr-sm-2 w100",attrs:{"type":"submit"},on:{"click":function($event){return _vm.redirect('Home')}}},[_c('i',{staticClass:"fas fa-arrow-alt-circle-left"}),_vm._v(" Voltar ")]),_c('b-button',{staticClass:"my-2 my-sm-0 mr-sm-2 w100",attrs:{"variant":"primary","type":"submit","disabled":!_vm.selectedOptionCompany || _vm.selected.length == 0 || !_vm.image},on:{"click":_vm.createBanner}},[_c('i',{staticClass:"fa fa-check-circle"}),_vm._v(" Gravar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }