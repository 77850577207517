import Loading from "vue-loading-overlay";
import Datepicker from "vuejs-datepicker";
import Timeselector from "vue-timeselector";
import moment from "moment";
import axios from "axios";

import PaginationNav from "../../../../../components/PaginationNav.vue";

import * as config from "@/config.json";

export default {
  name: "SelectCustomer",
  components: {
    Loading,
    Datepicker,
    Timeselector,
    moment,
    PaginationNav
  },

  data: () => {
    return {
      token: "",
      isLoading: false,
      qtdCustomersRegistered: 0,
      filterCustomers: "",
      pagination: {},
      paginationRange: 11,
      currentPageCustomers: 1,
      perPageCustomers: 5,

      currentPageSites: 1,
      perPageSites: 7,
      sites: [],
      fieldsSites: [{
          key: "selected",
          label: "Selecionado"
        }, {
          key: "id",
          label: "ID"
        },
        {
          key: "fantasy",
          label: "Nome"
        },
      ],

      fieldsSitesMobile: [{
          key: "selected",
          label: ""
        },
        {
          key: "name",
          label: "Nome",
        },
      ],

      filterOnCustomers: [],
      appsId: [],
      tabIndex: 0,

      selectMode: 'multi',
      selectModeMobile: 'multi',

      options: [{
          value: null,
          text: "Selecione o tipo do desconto"
        },
        {
          value: 1,
          text: "Porcentagem"
        },
        {
          value: 2,
          text: "Valor"
        },
      ],

      customerDevicesFilter: 1,
      customers: [],
      customersFields: [{
          key: "id",
          label: "ID"
        },
        {
          key: "name",
          label: "Nome"
        },
        {
          key: "email",
          label: "E-Mail"
        },
        {
          key: "birthDate",
          label: "Nascimento"
        },
        {
          key: "phone",
          label: "Telefone"
        },
        {
          key: "selecionado",
          label: "Selecionar"
        },
      ],

      customersFieldsSelected: [{
          key: "id",
          label: "ID"
        },
        {
          key: "name",
          label: "Nome"
        },
        {
          key: "email",
          label: "E-Mail"
        },
        {
          key: "birthDate",
          label: "Nascimento"
        },
        {
          key: "phone",
          label: "Telefone"
        },
        {
          key: "selecionado",
          label: "Desmarcar Seleção"
        },
      ],

      customersSelected: [],

      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 0,
        masked: false,
      },

      birthDateStart: null,
      birthDateEnd: null,
      sitesId: ''
    };
  },

  methods: {
    redirect() {
      const ref = localStorage.getItem("redirectDiscount") ? localStorage.getItem("redirectDiscount") : "home";

      localStorage.removeItem("redirectDiscount");
      this.$router.push(ref);
    },

    onFilteredCustomers() {
      this.currentPageCustomers = 1;
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    removeById(id) {
      const arr = this;
      arr.customersSelected.forEach(function (el, index) {
        if (el.id === id) {
          arr.customersSelected.splice(index, 1);
        }
      });
    },

    clearCustomers() {
      this.customersSelected = [];
      this.customersIdCampaign = [];
    },

    verifyCustomersSelected(id) {
      const filteredCustomers = this.customersSelected.filter(
        (customers) => customers.id == id
      );
      return filteredCustomers.length > 0;
    },

    selectCustomers(record) {
      const customer = this.customersSelected.find(
        (customer) => customer.id == record.id
      );

      if (!customer) {
        this.customersSelected.push({
          id: record.id,
          name: record.name,
          email: record.email,
          birthDate: record.birthDate,
          phone: record.phone
        });
      } else {
        this.removeById(record.id);
      }

      localStorage.removeItem("idCustomersCouponDiscount");

      if (this.customersSelected.length > 0) {
        if (this.customersSelected.length > 0) {
          const arrayCustomers = this.customersSelected.map(customer => customer.id);
          const idCustomers = new Array(arrayCustomers.join(","));

          localStorage.setItem("idCustomersCouponDiscount", idCustomers)
        }

        this.$emit("can-continue", {
          value: true
        });
      } else {
        this.$emit("can-continue", {
          value: false
        });
      }
    },

    navigate(page) {
      this.searchCustomers(page);
    },

    selectAllRowsSites() {
      this.$refs.selectableTableSites.selectAllRows()
      this.$refs.selectableTableSitesMobile.selectAllRows()
    },

    clearSelectedSites() {
      this.$refs.selectableTableSites.clearSelected()
      this.$refs.selectableTableSitesMobile.clearSelected()
    },

    async getLocalStorage() {
      this.idCustomersCouponDiscount = localStorage.getItem("idCustomersCouponDiscount")
    },

    async clearSearch() {
      this.filterCustomers = "";
      this.pagination = [];
    },

    async clearBirthDate() {
      this.birthDateStart = null
      this.birthDateEnd = null
      await this.searchCustomers(this.currentPageCustomers)
    },

    clearFilterCustomer() {
      this.filterCustomers = ''
    },

    async onRowSelectedSites(sites) {
      if (sites != '') {
        const arraySitesId = await Promise.all(sites.map(site => {
          return site.id
        }))

        this.sitesId = new Array(arraySitesId.join(","));
      } else {
        this.sitesId = null
      }
      await this.searchCustomers(this.currentPageCustomers);
    },

    async buildDataAppIds(appIds) {
      const data = {};

      data.appIds = new Array(appIds.join(","));

      return data;
    },

    async getSitesByAppType() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/sites/app`;
      const data = await this.buildDataAppIds(this.appsId)

      try {
        const response = await axios({
          url,
          data,
          method: "post",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.sites = response.data
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async searchCustomers(page) {
      const url = `${config.default.urlBase}/customers/search`;
      const builtCustomers = await this.buildCustomers(this.appsId, this.sitesId, this.birthDateStart, this.birthDateEnd, false, this.customerDevicesFilter, this.filterCustomers, page, this.perPageCustomers);

      try {
        const response = await axios({
          url,
          method: "POST",
          data: builtCustomers,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPageCustomers;
          this.pagination.paginationRange = this.paginationRange;

          this.customers = response.data.items;

          this.qtdCustomersRegistered = response.data.records;

          localStorage.removeItem("idCustomersCouponDiscount");

          if ((this.tabIndex == 0) && (this.qtdCustomersRegistered > 0)) {
            this.$emit("can-continue", {
              value: true
            });
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.customers = [];
        this.pagination = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os clientes! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async buildCustomers(appsId, sitesId, birthDateStart, birthDateEnd, unsubscribed, customerDevicesFilter, name, page, pageSize) {
      const data = new Object();

      const appIdsArray = new Array();
      appIdsArray.push(appsId);

      const appIdsSelected = new Array(appIdsArray.join(","));
      data.appsId = appIdsSelected;

      if (sitesId) {
        const sitesIdArray = new Array(sitesId.join(","));
        data.sitesId = sitesIdArray
      }

      if (birthDateStart != null && birthDateEnd != null) {
        this.birthDayStart = moment(birthDateStart, "DD/MM").format("DD");
        this.birthMonthStart = moment(birthDateStart, "DD/MM").format("MM");

        this.birthDayEnd = moment(birthDateEnd, "DD/MM").format("DD");
        this.birthMonthEnd = moment(birthDateEnd, "DD/MM").format("MM");

        data.birthDayStart = this.birthDayStart;
        data.birthDayEnd = this.birthDayEnd;
        data.birthMonthStart = this.birthMonthStart;
        data.birthMonthEnd = this.birthMonthEnd;
      }

      if (unsubscribed != null) {
        data.unsubscribed = unsubscribed;
      }


      data.customerDevicesFilter = customerDevicesFilter;

      data.name = name;

      data.page = page;
      data.pageSize = pageSize;

      return data;
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },
  },

  computed: {
    paginationCustomers() {
      return this.customers.length;
    },
  },

  updated() {
    if (!this.expira) {
      this.horaFinal = null
    }
  },

  async beforeMount() {
    this.isLoading = true;


    const appsIdArray = new Array();
    appsIdArray.push(localStorage.getItem("appsId-CouponsDiscount"));

    this.appsId = new Array(appsIdArray.join(","));

    this.token = localStorage.getItem("token");

    await this.getLocalStorage();

    await this.getSitesByAppType()

    await this.searchCustomers(this.currentPageCustomers);

    this.isLoading = false;
  },
};