import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../components/PaginationNav.vue";
import PaginationMobile from "../../components/PaginationMobile.vue";


import * as config from "@/config.json";

export default {
  name: "ProductsList",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },
  props: ["source"],
  data: () => {
    return {
      permissions: '',
      isAllowedEditProduct: false,
      pagination: {},
      paginationRange: 11,
      currentPage: 1,
      perPage: 8,
      product: null,
      token: null,
      items: [],
      fields: [{
          key: "isInactive",
          label: "Ativo"
        },
        {
          key: "imageUrl",
          label: "Foto"
        },
        {
          key: "sku",
          label: "Barras"
        },
        {
          key: "description",
          label: "Descrição"
        },
        {
          key: "groupImageUrl",
          label: "Foto Grupo"
        },
        {
          key: "groupDescription",
          label: "Grupo"
        },
        {
          key: "availableQuantity",
          label: "Estoque"
        },
        {
          key: "minimumQuantity",
          label: "Qtd Mínima"
        },
        {
          key: "maximumQuantity",
          label: "Qtd Máxima"
        },
        {
          key: "idealQuantity",
          label: "Qtd Ideal"
        },
        {
          key: "costPrice",
          label: "Custo"
        },
        {
          key: "salePrice",
          label: "Valor"
        },
        {
          key: "action",
          label: ""
        },
      ],
      itemsCompany: [],
      fieldsCompany: [{
          key: "isInactive",
          label: "Ativo"
        },
        {
          key: "imageUrl",
          label: "Foto"
        },
        {
          key: "sku",
          label: "Barras"
        },
        {
          key: "description",
          label: "Descrição"
        },
        {
          key: "imageGroupUrl",
          label: "Foto Grupo"
        },
        {
          key: "groupDescription",
          label: "Grupo"
        },
        {
          key: "details",
          label: ""
        },
      ],
      details: [],
      fieldsDetailsProduct: [{
          key: "siteId",
          label: "Id Loja"
        }, {
          key: "site",
          label: "Loja"
        },
        {
          key: "availableQuantity",
          label: "Estoque"
        },
        {
          key: "maximumQuantity",
          label: "Qtd Máxima"
        },
        {
          key: "minimumQuantity",
          label: "Qtd Mínima"
        },
        {
          key: "idealQuantity",
          label: "Qtd Ideal"
        },
        {
          key: "costPrice",
          label: "Custo"
        },
        {
          key: "salePrice",
          label: "Valor"
        },
        {
          key: "isInactive",
          label: "Ativo"
        },
        {
          key: "action",
          label: ""
        },
      ],
      options: [{
          value: null,
          text: "Descrição",
        },
        {
          value: 1,
          text: "Barras",
        },
      ],
      isLoading: false,
      selectedOption: null,
      selectedOptionCompany: null,
      optionsCompany: [],
      typeUser: null,
      totalRows: 0,
      image: null,
      description: null,
      totalRecords: 0,
      selectedProductsRadio: 0,
      optionsSelectProductsRadio: [{
          item: 0,
          name: 'Todos'
        },
        {
          item: 1,
          name: 'Ativos'
        },
        {
          item: 2,
          name: 'Inativos'
        },
      ],

      stockProduct: 0,
      maximumQuantityProduct: 0,
      minimumQuantityProduct: 0,
      idealQuantityProduct: 0,
      costProduct: 0,
      valueProduct: 0,
      promotionPriceProduct: 0,
      loyalPriceProduct: 0,
      inactiveProduct: false,
      state: null,
      productSiteId: null,
      productSelected: []
    };
  },
  methods: {
    redirect(ref) {
      if (ref == "Cadastro") {
        this.$router.push(`/add-product`);
      }
      if (ref == "Home") {
        this.$router.push(`/home`);
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async closeModalEditProduct() {
      await this.$refs.editProduct.hide();

      if (this.details.length > 0) {
        await this.showDetailsProduct(this.productSelected)
      } else {
        await this.getProducts(this.currentPage)
      }
    },

    async showEditProduct(product) {
      this.productSiteId = product.productSiteId
      this.inactiveProduct = product.isInactive == 1 ? true : false

      this.stockProduct = product.availableQuantity.replace(',', '.')
      this.stockProduct = parseFloat(this.stockProduct)

      this.costProduct = product.costPrice.replace('R$', '').trim()
      this.costProduct = this.costProduct.replace(',', '.')
      this.costProduct = parseFloat(this.costProduct)

      this.valueProduct = product.salePrice.replace('R$', '').trim()
      this.valueProduct = this.valueProduct.replace(',', '.')
      this.valueProduct = parseFloat(this.valueProduct)

      this.promotionPriceProduct = product.promotionPrice.replace('R$', '').trim()
      this.promotionPriceProduct = this.promotionPriceProduct.replace(',', '.')
      this.promotionPriceProduct = parseFloat(this.promotionPriceProduct)

      this.loyalPriceProduct = product.loyalPrice.replace('R$', '').trim()
      this.loyalPriceProduct = this.loyalPriceProduct.replace(',', '.')
      this.loyalPriceProduct = parseFloat(this.loyalPriceProduct)

      this.maximumQuantityProduct = product.maximumQuantity.replace(',', '.').trim()
      this.maximumQuantityProduct = parseFloat(this.maximumQuantityProduct)

      this.minimumQuantityProduct = product.minimumQuantity.replace(',', '.').trim()
      this.minimumQuantityProduct = parseFloat(this.minimumQuantityProduct)

      this.idealQuantityProduct = product.idealQuantity.replace(',', '.').trim()
      this.idealQuantityProduct = parseFloat(this.idealQuantityProduct)

      this.$refs.editProduct.show();
    },

    async showDetailsProduct(product) {
      this.productSelected = product

      if (product.imageUrl) {
        this.image = product.imageUrl;
      } else {
        this.image = "http://img.accesys.com.br/produtos/semimagem.png"
      }

      this.description = product.description;
      await this.getDetailsProduct(product.id);

      if (this.details.length > 0) {
        this.$refs.detailsProduct.show();
      } else {
        this.getNotification(
          "Aviso",
          "Nenhum detalhe do produto foi encontrado",
          "warning"
        );
      }
    },

    cleanSearch() {
      this.product = "";
      this.getProducts(this.currentPage);
    },

    navigate(page) {
      this.getProducts(page);
    },

    buildParams(company, option, selectedProductsRadio, pagina) {
      let params = new Object();

      if (company != null) {
        params.companyId = company
      }

      if (option == null) {
        params.description = this.product;
      }

      if (option == 1) {
        params.sku = this.product;
      }

      if (selectedProductsRadio == 1) {
        params.isInactive = false
      }

      if (selectedProductsRadio == 2) {
        params.isInactive = true
      }

      params.pageSize = this.perPage;
      params.page = pagina;

      return params;
    },

    async getCompanies() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsCompany.length == 1) {
            this.selectedOptionCompany = this.optionsCompany[0].value;
            this.getProducts(this.currentPage);
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getProducts(pagina) {
      const url = `${config.default.urlBase}/sites/products/search`;
      const params = this.buildParams(this.selectedOptionCompany, this.selectedOption, this.selectedProductsRadio, pagina);

      this.productSelected = []

      try {
        const response = await axios({
          url: url,
          method: "get",
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          if (this.typeUser == "site") {
            // this.items = response.data.items;
            const products = await Promise.all(
              response.data.items.map(async (product) => {
                return {
                  ...product,
                  availableQuantity: String(product.availableQuantity).replace('.', ',').trim(),
                  maximumQuantity: String(product.maximumQuantity).replace('.', ',').trim(),
                  minimumQuantity: String(product.minimumQuantity).replace('.', ',').trim(),
                  idealQuantity: String(product.idealQuantity).replace('.', ',').trim(),
                };
              })
            )
            this.items = products
          } else {
            this.itemsCompany = response.data.items;
          }

          this.totalRows = response.data.records;
          this.totalRecords = response.data.records;
        }

      } catch (error) {
        console.error({
          errorMessage: `[GetProducts] ${error}`,
        });

        this.isLoading = false;

        this.items = [];
        this.itemsCompany = [];
        this.pagination = [];
        this.totalRecords = 0;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os produtos! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getDetailsProduct(id) {
      const url = `${config.default.urlBase}/sites/products/${id}/details`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.details = response.data;
        }
      } catch (error) {
        console.error({
          errorMessage: `[GetDetailsProduct] ${error}`,
        });

        this.isLoading = false;

        this.details = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os detalhes do produto! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    verifyState() {
      if (
        this.stockProduct != '' &&
        this.costProduct != '' &&
        this.valueProduct != ''
      )
        return true
      else
        return false
    },

    async buildDataProduct(productSiteId, isInactive, availableQuantity, costPrice, salePrice, promotionPrice, loyalPrice, minimumQuantity, maximumQuantity, idealQuantity) {
      const availableQuantityStr = availableQuantity
      const costPriceStr = costPrice
      const salePriceStr = salePrice
      const promotionPriceStr = promotionPrice
      const loyalPriceStr = loyalPrice
      const minimumQuantityStr = minimumQuantity
      const maximumQuantityStr = maximumQuantity
      const idealQuantityStr = idealQuantity

      return {
        productSiteId: parseInt(productSiteId),
        isInactive: Boolean(isInactive),
        availableQuantity: parseFloat(availableQuantityStr),
        costPrice: parseFloat(costPriceStr),
        salePrice: parseFloat(salePriceStr),
        promotionPrice: parseFloat(promotionPriceStr),
        loyalPrice: parseFloat(loyalPriceStr),
        minimumQuantity: parseFloat(minimumQuantityStr),
        maximumQuantity: parseFloat(maximumQuantityStr),
        idealQuantity: parseFloat(idealQuantityStr)
      }
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    async updateProduct() {
      this.isLoading = true
      const url = `${config.default.urlBase}/sites/products`;
      const data = await this.buildDataProduct(
        this.productSiteId,
        this.inactiveProduct,
        this.stockProduct,
        this.costProduct,
        this.valueProduct,
        this.promotionPriceProduct,
        this.loyalPriceProduct,
        this.minimumQuantityProduct,
        this.maximumQuantityProduct,
        this.idealQuantityProduct,
      )

      console.log({
        data
      })

      try {
        const response = await axios({
          url,
          method: "PATCH",
          data,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.isLoading = false

          await this.closeModalEditProduct();

          await this.$alert(
            "Produto Atualizado!",
            "Sucesso",
            "success"
          );
        }
      } catch (error) {
        console.error({
          errorMessage: `[GetDetailsProduct] ${error}`,
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao atualizar o produto! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    getUserPermissions() {
      this.permissions = JSON.parse(sessionStorage.getItem("permissions"))

      const cost_Price = this.permissions.find(
        (permission) => permission.command === "cost_price"
      )

      if (cost_Price) {
        this.isAllowedEditProduct = true
      } else {
        this.isAllowedEditProduct = false
      }
    }

  },

  async updated() {
    this.state = await this.verifyState()
  },

  computed: {
    stockProductState() {
      return this.stockProduct ? true : false
    },
    valueProductState() {
      return this.valueProduct ? true : false
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.state = this.verifyState();
    this.token = localStorage.getItem("token");
    this.typeUser = localStorage.getItem("typeUser");

    this.getUserPermissions();
    await this.getCompanies();

    this.isLoading = false;
  },
};