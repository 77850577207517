import Loading from "vue-loading-overlay";
import axios from "axios";

import * as config from "@/config.json";

export default {
  name: "SelectApps",
  components: {
    Loading,
  },
  data: () => {
    return {
      isLoading: false,
      url: null,
      token: null,
      selectMode: 'multi',
      selectModeMobile: 'multi',
      currentPage: 1,
      perPage: 3,
      selected: [],
      companies: [],
      fieldsCompanies: [{
          key: "selected",
          label: "Selecionado"
        },
        {
          key: "id",
          label: "Id",
        },
        {
          key: "name",
          label: "Nome",
        },
      ],

      fieldsCompaniesMobile: [{
          key: "selected",
          label: ""
        },
        {
          key: "name",
          label: "Nome",
        },
      ],
    }
  },

  methods: {
    onRowSelected(items) {
      this.selected = items

      if (this.selected.length <= 0) {
        this.$emit("can-continue", {
          value: false
        });
      } else {
        this.$emit("can-continue", {
          value: true
        });
      }

      localStorage.removeItem("appsId-CouponsDiscount");

      if (this.selected.length > 0) {
        const arrayApps = this.selected.map(app => app.id);
        const appsId = new Array(arrayApps.join(","));

        localStorage.setItem("appsId-CouponsDiscount", appsId)
      }
    },

    async getLocalStorage() {
      this.appsIdCouponDiscount = localStorage.getItem("appsId-CouponsDiscount")
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
      this.$refs.selectableTableMobile.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.$refs.selectableTableMobile.clearSelected()
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async getApps() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/apps/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });


        if (response.status == 200) {
          this.isLoading = false;

          this.companies = response.data;

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar os aplicativos! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");

    await this.getLocalStorage();

    await this.getApps();

    this.isLoading = false;
  }
}