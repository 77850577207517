import PictureInput from "vue-picture-input";
import Loading from "vue-loading-overlay";
import axios from "axios";

import * as config from "@/config.json";

export default {
  name: "BannersAppManager",
  components: {
    Loading,
    PictureInput
  },
  data: () => {
    return {
      isLoading: false,
      url: null,
      token: null,
      selectMode: 'multi',
      selectModeMobile: 'multi',
      currentPage: 1,
      perPage: 3,
      image: null,
      dataVerified: false,
      selected: [],
      companies: [],
      fieldsCompanies: [{
          key: "selected",
          label: "Selecionado"
        },
        {
          key: "id",
          label: "Id",
        },
        {
          key: "name",
          label: "Nome",
        }
      ],

      fieldsCompaniesMobile: [{
          key: "selected",
          label: ""
        },
        {
          key: "id",
          label: "Id",
        },
        {
          key: "name",
          label: "Nome",
        },
      ],
    }
  },

  methods: {
    redirect() {
      const ref = localStorage.getItem("redirectBannersApp") ? localStorage.getItem("redirectBannersApp") : "home";
      localStorage.removeItem("redirectBannersApp");
      this.$router.push(ref);
    },

    onRemoved() {
      this.image = '';
    },

    onChange(image) {
      if (image) {
        this.image = image;
      }
    },

    onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
      this.$refs.selectableTableMobile.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.$refs.selectableTableMobile.clearSelected()
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },


    verifyDataBanner(data, field) {
      if ((!data) || (data.length == 0)) {
        this.getNotification(
          "Aviso",
          `Preencha o campo ${field}`,
          "warning"
        );
        return false;
      }
      return true;
    },

    async buildDataBanner(image, apps) {
      console.log({
        image
      })
      const arraySites = apps.map(app => app.id);

      const appsId = new Array(arraySites.join(","));


      if (this.verifyDataBanner(image, "Imagem") && this.verifyDataBanner(apps, "Empresa")) {
        this.dataVerified = true;
        return {
          image,
          appsId
        }
      } else {
        this.dataVerified = false;
      }
    },

    async createBanner() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/apps/banners`;

      const data = await this.buildDataBanner(this.image, this.selected);

      if (!data) {
        this.isLoading = false;
        return;
      }

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status === 200) {
          this.isLoading = false;

          await this.$alert(
            "Banner cadastrado!",
            "Sucesso",
            "success"
          );

          this.$refs.selectableTable.clearSelected();
          this.$refs.selectableTableMobile.clearSelected();
          this.$refs.pictureInput.removeImage();

          this.$router.go()
        }
      } catch (error) {
        console.log({
          error
        })
        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }
        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          if (this.dataVerified) {
            await this.$alert(
              `Ocorreu um erro ao criar o banner! ${error.response.data.message}`,
              "Aviso",
              "warning"
            );
            this.dataVerified = false;
          }
        }
      }
    },

    async getCompanies() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/apps/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.companies = response.data;

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar os aplicativos! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  computed: {
    rows() {
      return this.bannersCompanyUser.length;
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    await this.getCompanies();
    this.isLoading = false;
  }
}