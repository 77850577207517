import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../../components/PaginationNav.vue";
import PaginationMobile from "../../../components/PaginationMobile.vue";
import moment from "moment";

export default {
  name: "SalesList",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },
  data: () => {
    return {
      pagination: {},
      paginationRange: 6,
      currentPage: 1,
      perPage: 9,
      customer: null,
      token: null,
      itemsDetails: [],
      fieldsDetails: [],
      items: [],
      fields: [{
          key: "id",
          label: "ID",
        },
        {
          key: "createdAt",
          label: "Data",
        },
        {
          key: "customerId",
          label: "ID do Cliente",
        },
        {
          key: "customerName",
          label: "Nome do Cliente",
        },
        {
          key: "itemsSumQuantity",
          label: "Qtde Itens",
        },
        {
          key: "totalValue",
          label: "Valor Total",
        },
        {
          key: "info",
          label: "",
        }
      ],
      selectedOption: null,
      options: [{
          value: null,
          text: "Sem Intervalo de Datas",
        },
        {
          value: 1,
          text: "Com Intervalo de Datas",
        },
      ],
      selectedFilterOption: null,
      filterOptions: [{
          value: null,
          text: "Todas",
        },
        {
          value: 1,
          text: "Somente Processadas",
        },
        {
          value: 2,
          text: "Somente Estornadas",
        }
      ],
      isLoading: false,
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOptionSite: null,
      optionsSite: [],
      typeUser: null,
      itemsSanitized: [],
      qtdOrders: 0,
      totalAmount: 0,
      averageAmount: 0,
      pagePagination: null,

      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      totalRecords: 0,

      companyIdDashboard: null,
      siteIdDashboard: null,
      selectedOptionsDateDashboard: null,
      startDateDashboard: null,
      endDateDashboard: null
    };
  },
  methods: {
    redirect(ref) {
      if (ref == "Home") {
        this.$router.push(`/home`);
      }
    },

    async navigate(page) {
      await this.getOrders(page);
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async getCompanies() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/companies/user`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsCompany = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsCompany.length == 1) {
            this.selectedOptionCompany = this.optionsCompany[0].value;
            this.getSitesByCompany(this.selectedOptionCompany);
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsCompany = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`;

      this.selectedOptionSite = []

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.sites = response.data;

          this.optionsSite = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: `${item.id} ${item.name}`,
            };
          }));

          if (this.optionsSite.length == 1) {
            this.selectedOptionSite = this.optionsSite[0].value;
            await this.getOrders(this.currentPage);
          }

          if (this.optionsSite.length > 1 && this.typeUser != 'site') {
            await this.getOrders(this.currentPage)
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsSite = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    },

    async sanitizedItems(items) {
      this.itemsSanitized = await Promise.all(items.map(item => {
        return {
          ...item,
          customerId: item.customerId != null ? item.customerId : 0,
          customerName: item.customerName != null ? item.customerName : "Consumidor",
        };
      }));
    },

    async getOrders(page) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/sites/orders/search`;
      const params = this.buildParams(this.selectedOptionCompany, this.selectedOptionSite, this.selectedOption, this.selectedFilterOption, page);

      localStorage.setItem('currentPageSales', page);


      if (params != null) {
        try {
          const response = await axios({
            url,
            method: "get",
            params,
            headers: {
              authorization: `Bearer ${this.token}`
            },
          });

          if (response.status == 200) {
            this.isLoading = false;

            this.pagination = response.data;
            this.pagination.rowsPerPage = this.perPage;
            this.pagination.paginationRange = this.paginationRange;

            this.items = response.data.items;
            await this.sanitizedItems(this.items);

            this.qtdOrders = response.data.records;
            this.totalAmount = response.data.totalAmount;
            this.averageAmount = response.data.averageAmount;
            this.totalRecords = response.data.records;
          }
        } catch (error) {
          console.log({
            error
          });

          this.isLoading = false;

          this.items = [];
          this.itemsSanitized = [];
          this.pagination = [];

          this.qtdOrders = 0;
          this.totalAmount = 0;
          this.averageAmount = 0;
          this.totalRecords = 0;

          if (!error.response) {
            await this.$alert(
              "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
              "Erro",
              "error"
            );
          }

          if (error.response.status == 403) {
            await this.$alert(
              "Sua sessão expirou. Faça o login novamente!",
              "Aviso",
              "warning"
            );
            this.$router.push("login");
          } else if (error.response.status == 404) {
            this.getNotification(
              "Aviso",
              `${error.response.data.message}`,
              "warning"
            );
          } else {
            await this.$alert(
              `Ocorreu um erro ao buscar as vendas! ${error.response.data.message}`,
              "Aviso",
              "warning"
            );
          }
        }
      }
    },

    readyParamsOrder() {
      this.companyIdDashboard = this.$route.params.companyId;
      this.siteIdDashboard = this.$route.params.siteId;
      this.selectedOptionsDateDashboard = this.$route.params.selectedOption;
      this.startDateDashboard = this.$route.params.startDate;
      this.endDateDashboard = this.$route.params.endDate;
    },

    async getOrderDetails(orderId) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/sites/orders/${orderId}/details`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;
          this.itemsDetails = response.data;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.itemsDetails = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar as vendas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async showDetails(order) {
      localStorage.setItem("orderId", order.id);
      localStorage.setItem("companyIdOrder", this.selectedOptionCompany);
      localStorage.setItem("siteIdOrder", this.selectedOptionSite);

      localStorage.setItem("filterOption", this.selectedOption);
      localStorage.setItem("startDateOrder", this.startDate);
      localStorage.setItem("endDateOrder", this.endDate);

      localStorage.setItem('selectedFilterOptionSales', this.selectedFilterOption);

      this.$router.push("order-details");

    },

    buildParams(companyId, siteId, option, filter, page) {
      if (this.selectedOptionCompany != null || this.selectedOptionSite != null) {
        let params = new Object();

        if (companyId != null) {
          params.companyId = companyId
        }

        if (siteId != null) {
          params.siteId = siteId
        }

        if (option == 1) {
          if (this.startDate != null && this.endDate != null) {
            if (this.endDate < this.startDate) {
              this.getNotification(
                "Aviso",
                "A data final deve ser maior que a data inicial!",
                "warning"
              );

              return null;
            } else {
              params.startDate = moment(this.startDate).format("YYYY-MM-DD 00:00:00");
              params.endDate = moment(this.endDate).format("YYYY-MM-DD 23:59:59");
            }
          }
        }

        if (filter == 2) {
          params.isRefunded = true
        }

        params.pageSize = this.perPage;
        params.page = page;

        return params;
      } else {
        return null
      }
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.typeUser = localStorage.getItem("typeUser");

    await this.getCompanies();

    await this.readyParamsOrder();

    if (this.companyIdDashboard && this.siteIdDashboard && this.selectedOptionsDateDashboard && this.startDateDashboard && this.endDateDashboard) {
      this.selectedOptionCompany = this.companyIdDashboard;

      this.selectedOption = this.selectedOptionsDateDashboard;
      this.startDate = this.startDateDashboard;
      this.endDate = this.endDateDashboard

      await this.getSitesByCompany(this.selectedOptionCompany);
      this.selectedOptionSite = this.siteIdDashboard;
    } else if ((localStorage.getItem("companyIdOrder")) && (localStorage.getItem("siteIdOrder"))) {
      this.selectedOptionCompany = localStorage.getItem("companyIdOrder");

      await this.getSitesByCompany(this.selectedOptionCompany);
      this.selectedOptionSite = localStorage.getItem("siteIdOrder");

      localStorage.removeItem("companyIdOrder");
      localStorage.removeItem("siteIdOrder");
      localStorage.removeItem("orderId");
    }

    if (localStorage.getItem("filterOption") == 1) {
      this.selectedOption = 1;
      this.startDate = localStorage.getItem("startDateOrder");
      this.endDate = localStorage.getItem("endDateOrder");

      localStorage.removeItem("filterOption");
      localStorage.removeItem("startDateOrder");
      localStorage.removeItem("endDateOrder");
    } else {
      this.startDate = moment(new Date()).format("YYYY-MM-DD");
      this.endDate = moment(new Date()).format("YYYY-MM-DD");
    }

    if (localStorage.getItem('selectedFilterOptionSales')) {
      this.selectedFilterOption = localStorage.getItem('selectedFilterOptionSales');

      localStorage.removeItem('selectedFilterOptionSales')
    }

    this.pagePagination = localStorage.getItem('currentPageSales');

    if (!this.pagePagination) {
      await this.getOrders(this.currentPage);
    } else {
      localStorage.removeItem('currentPageSales');
      await this.navigate(this.pagePagination);
    }


    this.isLoading = false;
  },
};