import axios from "axios";
import Loading from "vue-loading-overlay";
import PaginationNav from "../../components/PaginationNav.vue";
import PaginationMobile from "../../components/PaginationMobile.vue";
import * as config from "@/config.json";
import moment from "moment";

export default {
  name: "HomeComp",

  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },

  data: () => {
    return {
      isLoading: false,
      loader: 'dots',
      opacity: 0.95,
      token: null,
      dataDashboard: [],
      paramsOrders: [],
      currentPage: 1,
      perPage: 10,
      pagination: {},
      paginationRange: 11,
      selectedPeriod: null,
      totalOrders: [],
      amountOrdersToday: null,
      valueOrdersToday: null,
      averageTicketToday: null,
      amountOrdersSevenDays: null,
      valueOrdersSevenDays: null,
      averageTicketSevenDays: null,
      amountOrdersThirtyDays: null,
      valueOrdersThirtyDays: null,
      averageTicketThirtyDays: null,
      period: [{
          value: 0,
          text: 'Hoje'
        },
        {
          value: 7,
          text: '7 Dias'
        },
        {
          value: 30,
          text: '30 Dias'
        },
      ],
      items: [],
      fields: [{
          key: "position",
          label: "Posição",
        },
        {
          key: "sku",
          label: "Barras"
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "quantity",
          label: "Quantidade",

        },
        {
          key: "totalValue",
          label: "Valor Total",
        }
      ],
      fieldsMobile: [{
          key: "position",
          label: "Posição",
        },
        {
          key: "sku",
          label: "Barras"
        },
        {
          key: "description",
          label: "Descrição",
        },
        {
          key: "quantity",
          label: "Quantidade",

        },
        {
          key: "totalValue",
          label: "Valor Total",
        }
      ],

      periodSearch: 0,
      chartOptionsOrdersByGroup: {},
      labelsGroup: [],
      seriesGroup: [],

      chartOptions: {
        chart: {
          height: 360,
          width: 700,
          type: "bar",
          background: '#fff',

          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,

            animateGradually: {
              enabled: true,
              delay: 150
            },

            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },

        fill: {
          colors: ['#67A89A'],
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: []
          }
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val > 0 ? val.toFixed(2) : 0
          },
          style: {
            colors: ['#333'],
            fontSize: '12px',
            fontWeight: 'normal',
          },
          offsetY: 5,

          dropShadow: {
            color: '#111',
            blur: 3,
            opacity: 0.8,
          },
        },

        title: {
          text: "Valor de Vendas"
        },

        plotOptions: {
          bar: {
            columnWidth: '50%',
            borderRadius: 5,
            dataLabels: {
              position: 'top',
              orientation: 'vertical',
            }
          },
        },

      },
      series: [{
        name: 'Vendas',
        data: []
      }],

      xaxis: {
        type: "datetime"
      },

      posBySite: [],
      productsSite: [],
      paginationProductsSite: {},
      paginationRangeProductsSite: 11,
      perPageProductsSite: 10,
      currentPageProductsSite: 1,
    };
  },

  methods: {
    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async showModalTotens() {
      console.log({
        posBySite: this.posBySite 
      })
      if (this.posBySite.length > 0) {
        await this.$bvModal.show('rebootPOS');
      } else {
        this.getNotification(
          "Aviso",
          `Nenhum Totem configurado para o site selecionado!`,
          "warning"
        );
      }
    },

    async buildParamsGetPos(siteId) {
      let params = new Object();

      params.siteId = siteId

      return params
    },

    async getPosBySiteId(site) {
      this.isLoading = true
      const url = `${config.default.urlBase}/pos`;
      const params = await this.buildParamsGetPos(site.siteId)

      try {
        const response = await axios({
          url,
          method: 'GET',
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.isLoading = false

          this.posBySite = response.data;

          await this.showModalTotens();
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false
        this.posBySite = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        }
      }
    },

    async buildParamsOrder(companyId, siteId, selectedOption, startDate, endDate) {
      this.paramsOrders = {
        companyId,
        siteId,
        selectedOption,
        startDate,
        endDate
      }
    },

    navigate(page) {
      this.getTopOrderedProducts(page, this.periodSearch);
    },

    async buildDataCommand(deviceId, command) {
      let data = new Object();

      data.deviceId = deviceId,
        data.command = command

      return data
    },

    async commandPOS(pos, command) {
      this.isLoading = true;
      const url = `${config.default.urlBaseCustomer}/sites/pos/command`;
      const data = await this.buildDataCommand(pos.id, command)

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {

          this.isLoading = false;

          await this.getNotification(
            "Aviso",
            "Comando enviado com sucesso!",
            "success"
          );

          await this.$bvModal.hide('rebootPOS');
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        await this.$alert(
          `Não foi possivel executar o comando! ${error.response.data.message}`,
          "Aviso",
          "warning"
        );
      }
    },

    async getOrders(item) {
      const startDate = moment(new Date()).format('YYYY-MM-DD');
      const endDate = moment(new Date()).format('YYYY-MM-DD');

      await this.buildParamsOrder(item.companyId, item.siteId, 1, startDate, endDate);
      this.$router.push({
        name: "SalesList",
        params: this.paramsOrders
      });
    },

    buildParams(pagina, period) {
      let params = new Object();

      params.pageSize = this.perPage;
      params.page = pagina;
      params.period = period

      return params;
    },

    buildParamsChart(period, pagina) {
      let params = new Object();

      params.period = period;

      params.pageSize = this.perPage;
      params.page = pagina;

      return params;
    },


    async getDataChart(period) {
      this.isLoading = true;
      period == 0 ? await this.updateChart(period + 1) : await this.updateChart(period)
      await this.getTopOrderedProducts(this.currentPage, period);

      await this.getDashboard();

      this.isLoading = false;

      this.periodSearch = period
    },

    async updateChart(period) {
      const url = `${config.default.urlBase}/dashboards/landing-page/orders/search`;
      const params = this.buildParamsChart(period, this.currentPage)

      try {
        const response = await axios({
          url,
          method: "GET",
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        this.series = [];
        this.seriesGroup = [];

        if (response.status == 200) {
          const data = response.data.series

          const seriesMounted = await Promise.all(data.map(async item => {
            return {
              x: item.date,
              y: parseFloat(item.amount)
            }
          }))

          this.series = [{
            data: seriesMounted
          }];

          const dataOrderByGroup = response.data.seriesByGroup;

          const seriesOrdersByGroupMounted = await Promise.all(dataOrderByGroup.map(async item => {
            return item.percentualGroup
          }))
          this.seriesGroup = seriesOrdersByGroupMounted

          const labelsOrdersByGroupMounted = await Promise.all(dataOrderByGroup.map(async item => {
            return item.description
          }))
          this.labelsGroup = labelsOrdersByGroupMounted

          const newChartOptionsOrdersByGroup = {
            chart: {
              width: 450,
              height: 360,
              type: 'donut',
              dropShadow: {
                enabled: true,
                color: '#111',
                top: -1,
                left: 3,
                blur: 3,
                opacity: 0.2
              }
            },

            stroke: {
              width: 0,
            },

            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 270,
                donut: {
                  size: 70,
                  labels: {
                    show: true,
                    total: {
                      showAlways: true,
                      show: true,
                      formatter: function (w) {
                        return parseFloat((w.globals.seriesTotals.reduce((a, b) => {
                          return a + b
                        }, 0)).toFixed(2))
                      }
                    }
                  }
                }
              }
            },

            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },

              dropShadow: {
                blur: 3,
                opacity: 0.8
              },
            },

            fill: {
              type: 'gradient',
            },

            theme: {
              palette: ['palette6']
            },

            title: {
              text: "Vendas por Grupo"
            },

            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }],

            labels: this.labelsGroup,
          }

          this.chartOptionsOrdersByGroup = newChartOptionsOrdersByGroup;
        }

      } catch (error) {
        console.log({
          error
        })

        this.series = [];
        this.seriesGroup = [];

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
      }
    },

    async getTopOrderedProducts(page, period) {
      const url = `${config.default.urlBase}/dashboards/landing-page/top-ordered-products/search`;
      const params = this.buildParams(page, period)

      try {
        const response = await axios({
          url,
          method: "GET",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });

        if (response.status == 200) {
          this.items = response.data.items;
          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;
        }

      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false;

        this.items = [];
        this.pagination = [];
        this.pagination.rowsPerPage = [];
        this.pagination.paginationRange = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }
      }
    },

    openCheckHelth(url) {
      window.open(url, '_blank');
    },


    async buildParamsSite(companyId, siteId, fantasy) {
      return {
        companyId,
        siteId,
        fantasy
      }
    },

    async redirectProductsSite(site) {
      const paramsSite = await this.buildParamsSite(site.companyId, site.siteId, site.fantasy)

      this.$router.push({
        name: "ProductsSite",
        params: paramsSite
      });
    },

    async buildParamsMonitoringSite(siteId) {
      return {
        siteId
      }
    },

    async redirectMonitoringSite(site) {
      const paramsSite = await this.buildParamsMonitoringSite(site.siteId)

      this.$router.push({
        name: "Monitoring",
        params: paramsSite
      });
    },


    async getDashboard() {
      const url = `${config.default.urlBase}/dashboards/landing-page`;

      try {
        const response = await axios({
          url,
          method: "GET",
          headers: {
            authorization: `Bearer ${this.token}`
          }
        });


        if (response.status == 200) {
          this.dataDashboard = response.data.sites;

          this.amountOrdersToday = response.data.totals.amountOrdersToday;
          this.valueOrdersToday = response.data.totals.valueOrdersToday;
          this.averageTicketToday = response.data.totals.averageTicketToday;

          this.amountOrdersSevenDays = response.data.totals.amountOrdersSevenDays;
          this.valueOrdersSevenDays = response.data.totals.valueOrdersSevenDays;
          this.averageTicketSevenDays = response.data.totals.averageTicketSevenDays;

          this.amountOrdersThirtyDays = response.data.totals.amountOrdersThirtyDays;
          this.valueOrdersThirtyDays = response.data.totals.valueOrdersThirtyDays;
          this.averageTicketThirtyDays = response.data.totals.averageTicketThirtyDays;
        }
      } catch (error) {
        console.log({
          error
        })

        this.dataDashboard = [];

        this.totalOrders = [];

        this.amountOrdersToday = [];
        this.valueOrdersToday = [];
        this.averageTicketToday = [];

        this.amountOrdersSevenDays = [];
        this.valueOrdersSevenDays = [];
        this.averageTicketSevenDays = [];

        this.amountOrdersThirtyDays = [];
        this.valueOrdersThirtyDays = [];
        this.averageTicketThirtyDays = [];

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }
      }
    },

    async updateHome(page) {
      this.isLoading = true;
      await Promise.all([
        this.getDashboard(),
        this.updateChart(1),
        this.getTopOrderedProducts(page, 0)
      ])
      this.isLoading = false;
    },
  },

  async beforeMount() {
    this.token = localStorage.getItem('token');
    this.series = [];
    this.seriesGroup = [];

    await this.updateHome(this.currentPage, 0);
  },
};